/* src/animations.css */

@keyframes pulsate {
    0% {
      transform: scale(1);
      box-shadow: 0 0 10px rgba(0, 191, 255, 0.6);
    }
    50% {
      transform: scale(1.1);
      box-shadow: 0 0 20px rgba(0, 191, 255, 1);
    }
    100% {
      transform: scale(1);
      box-shadow: 0 0 10px rgba(0, 191, 255, 0.6);
    }
  }
  
  /* Optional: Apply smooth transitions */
  .neon-button {
    animation: pulsate 2s infinite;
  }
  