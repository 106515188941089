body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Bangers', cursive;
  background-image: url('./home-page.png'); /* Replace with your image path */
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
}

/* Optional: Add a semi-transparent overlay for better readability */
.background-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2); /* Adjust opacity and color as needed */
  z-index: -1;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
